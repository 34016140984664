<template>
    <div
        v-if="hasResource('message_add') || hasResource('message_update')"
        class="aops-notice-edit-text"
    >
        <edit-title :id="id" @handleBack="back" />
        <el-form
            ref="form"
            v-loading="isLoading"
            :model="form"
            :rules="formRules"
            label-width="80px"
            inline
        >
            <edit-type
                :id="id"
                ref="editTypeRef"
                :form="form"
                :add-cus-data-origin.sync="addCusDataOrigin"
                @onChangeForm="changeForm"
            />
            <div class="aop-sub-title">推送内容</div>
            <div v-for="item in langData" :key="item.type">
                <div class="aop-lang-text">语言：{{item.text}}</div>
                <edit-content
                    :lang="item.type"
                    :form="form"
                    :prop="item.prop"
                    @onPropValid="handlePropValid"
                />
            </div>
        </el-form>
        <div class="aop-edit-footer">
            <el-button class="up-button" @click="confirm">保存</el-button>
            <el-button class="other-button" @click="handlePreview">预览</el-button>
            <el-button
                v-if="hasResource('message_send')"
                class="other-button"
                @click="handleSend"
            >保存并推送</el-button>
        </div>
        <sendDialog
            :dialog-send.sync="dialogSend"
            :confirm-loading="confirmLoading"
            @onSendSubmit="sendSubmit"
        />
        <viewDialog
            :dialog-view.sync="dialogView"
            :data="viewData"
            :lang-data="langData"
        />
    </div>
</template>

<script>

import editMixins from './editMixins';

export default {
    mixins: [editMixins],
    /**
     * data
     * @returns {object}
    */
    data() {
        return {
            form: {
                way: 1, // 推送方式 默认定时推送
                scheduled_at: '', // 推送日期
                scheduled_time: '', // 推送时间
                type: 1, // 推送类型 1:图文消息
                send_all_customers: 1, // 推送范围 默认所有用户
                title: '', // 中文标题
                content: '', // 中文内容
                title_en: '', // 英文标题
                content_en: '', // 英文内容
                description: '', // 中文简介
                description_en: '', // 英文简介
                channel: '' // 推送渠道
            }
        };
    },
    computed: {
        /**
         * form校验规则
         * @returns {Object}
        */
        formRules() {
            const rule = { required: true, message: '需同时包含中英文推送信息，以确保用户浏览完整性', trigger: 'blur' };
            return {
                ...this.rules,
                description: [rule],
                description_en: [rule]
            };
        }
    },
    methods: {}
};
</script>
<style lang="less">
@import "./edit-text.less";
</style>
